<template>
    <b-sidebar id="sidebar-right" right shadow width="80%" bg-variant="white" backdrop>
        <template #default="{ hide }">
            <b-card title="SMS Template">
                <b-form>
                    <b-row>
                        <b-col md="12">
                            <b-form-group label="Screen Name">
                                <v-select placeholder="Please Select Screen Name" @input="selectScreenName"
                                    v-model=smsConfig.screenNameSelected :options="screenNameOptions" label="screenName">
                                </v-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-form-group label="Template Name">
                                <b-form-input v-model=smsConfig.templateName placeholder="Please Enter Template Name">
                                </b-form-input>
                            </b-form-group>
                        </b-col>

                        <b-col md="12">
                            <b-form-group label="Dynamic Variable">
                                <v-select placeholder="Please Select Dynamic Variable" @input="selectDynamicVariable"
                                    v-model="dynamicVariableSelected" :options="dynamicVariableOptions"
                                    label="dynamicVariableName"></v-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-form-group label="SMS Template">
                                <quill-editor v-model=smsConfig.smsTemplate :options="snowOption" />
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-form-group>
                                <b-form-checkbox v-model=smsConfig.isActive >IsActive</b-form-checkbox>
                            </b-form-group>
                        </b-col>
                        <b-col md="12" class="text-center">
                            <b-button variant="primary" class="mt-2 mr-2" v-on:click="addUpdateSmsTemplate"
                                @click="hide">{{ isEdit ?
        'Update' : 'Add'
                                }}</b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </b-card>
        </template>
    </b-sidebar>
</template>

<script>
import {
    BCard, BSidebar, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormTextarea, BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import api from '@/store/api'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import { integer } from 'vee-validate/dist/rules'
import util from '@/store/utils'

export default {
    components: {
        BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormTextarea, BSidebar,BFormCheckbox,
        vSelect,quillEditor
    },
    data(){
        return{
            screenNameSelected: '',
            screenNameOptions: util.screenNameOptions,
            templateName: '',
            dynamicVariableSelected: '',
            dynamicVariableOptions: util.dynamicVariableOptions,
            smsTemplate: '',
            isActive: false,
        }
    },
    methods: {
        selectScreenName() {
            this.smsConfig.templateName = this.smsConfig.screenNameSelected.screenName
        },

        selectDynamicVariable() {
            this.smsConfig.smsTemplate += '# ' + this.dynamicVariableSelected.dynamicVariableName + ' #'
        },
        addUpdateSmsTemplate() {
            let self = this;
            if (!(self.smsConfig.screenNameSelected.id == 0) && !(self.smsConfig.templateName == '') && !(self.smsConfig.smsTemplate == '')) {
                if (!this.isEdit) {
                    var axios = require('axios');
                    var data = JSON.stringify(
                        {
                            "screenName": this.smsConfig.screenNameSelected.id,
                            "templateName": this.smsConfig.templateName,
                            "smsTemplate": this.smsConfig.smsTemplate,
                            "active": this.smsConfig.isActive
                        }
                    );
                    axios(api.getApi('Post', '/masters/smsTemplates', data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));

                            self.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `SMS Template Added Successfully!`,
                                    icon: 'SaveIcon',
                                    variant: 'success',
                                },
                            })
                           
                            self.getSmsTemplate();
                            
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                } else {


                    var axios = require('axios');
                    var data = JSON.stringify(
                        {
                            "screenName": this.smsConfig.screenNameSelected.id,
                            "templateName": this.smsConfig.templateName,
                            "smsTemplate": this.smsConfig.smsTemplate,
                            "active": this.smsConfig.isActive
                        }
                    );
                    axios(api.getApi('put', '/masters/smsTemplates/' + this.smsConfig.id, data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));
                            self.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `SMS Template Updated Successfully!`,
                                    icon: 'SaveIcon',
                                    variant: 'success',
                                },
                            })
                            self.getSmsTemplate();
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            } else {
                this.$swal({
                    title: '',
                    text: "Please fill all the mandatory fields.",
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            }
        },
    },
    props: {
        isEdit: Boolean,
        getSmsTemplate: Function,
        smsConfig: Array
    },
}
</script>