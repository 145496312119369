<template>
    <b-card title="SMS Configuration">
        <b-tabs>
            <b-tab active title="SMS Templates">
                <b-card class="border mb-2">
                    <b-form>
                        <div>
                            <!-- search input -->
                            <div class="custom-search">
                                <b-row>
                                    <b-col md="5">
                                        <b-button variant="primary" class="mr-2" v-b-toggle.sidebar-right
                                            @click="addEdit(false, 0, '', '', '', false)">Add New
                                            Template</b-button>
                                    </b-col>
                                    <b-col md="7">
                                        <b-form-group>
                                            <div class="d-flex align-items-center">
                                                <label class="mr-1">Search</label>
                                                <b-form-input v-model="searchTerm" placeholder="Search" type="text"
                                                    class="d-inline-block" />
                                            </div>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </div>

                            <!-- table -->
                            <vue-good-table :line-numbers="true" :columns="columns" :rows="rows" :rtl="direction"
                                :search-options="{
                                    enabled: true,
                                    externalQuery: searchTerm
                                }" :pagination-options="{
    enabled: true,
    perPage: pageLength
}">
                                <template slot="table-row" slot-scope="props">

                                    <!-- Column: Name -->
                                    <span v-if="props.column.field === 'active'" class="text-nowrap">
                                        <span v-if="props.row.active === true" class="text-nowrap">
                                            <feather-icon icon="CheckIcon" class="isActive" />
                                        </span>
                                        <span v-if="props.row.active === false" class="text-nowrap">
                                            <feather-icon icon="XIcon" class="isInActive" />
                                        </span>
                                    </span>

                                    <!-- Column: Action -->
                                    <span v-else-if="props.column.field === 'action'">
                                        <span>
                                            <b-button
                                                @click="addEdit(true, props.row.id, props.row.screenName, props.row.templateName, props.row.smsTemplate, props.row.active)"
                                                variant="flat-primary" v-b-toggle.sidebar-right><feather-icon
                                                    icon="EditIcon" class="text-body align-middle mr-25" /></b-button>
                                            <b-button @click="deleteSmsTemplate(props.row.id)"
                                                variant="flat-primary"><feather-icon icon="TrashIcon"
                                                    class="text-body align-middle mr-25" /></b-button>
                                        </span>
                                    </span>

                                    <!-- Column: Common -->
                                    <span v-else>
                                        {{ props.formattedRow[props.column.field] }}
                                    </span>
                                </template>

                                <!-- pagination -->
                                <template slot="pagination-bottom" slot-scope="props">
                                    <div class="d-flex justify-content-between flex-wrap">
                                        <div class="d-flex align-items-center mb-0 mt-1">
                                            <span class="text-nowrap ">
                                                Showing 1 to
                                            </span>
                                            <b-form-select v-model="pageLength" :options="['10', '20', '50','100']" class="mx-1"
                                                @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                                            <span class="text-nowrap"> of {{ props.total }} entries </span>
                                        </div>
                                        <div>
                                            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength"
                                                first-number last-number align="right" prev-class="prev-item"
                                                next-class="next-item" class="mt-1 mb-0"
                                                @input="(value) => props.pageChanged({ currentPage: value })">
                                                <template #prev-text>
                                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                                </template>
                                                <template #next-text>
                                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                                </template>
                                            </b-pagination>
                                        </div>
                                    </div>
                                </template>
                            </vue-good-table>
                        </div>
                        <!-- <b-sidebar id="sidebar-right" right shadow bg-variant="white" backdrop> -->
                        <sms-template-edit :isEdit=isEdit :smsConfig=smsConfig :getSmsTemplate="getSmsTemplate" />
                        <!-- </b-sidebar> -->
                    </b-form>
                </b-card>

            </b-tab>
            <!-- <b-tab title="SMS Setup">
                <b-row class="border mb-2">
                    <b-col md="6">
                        <b-form-group label="Thanks Message">
                            <b-form-input placeholder="Please Enter Thanks Message."></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="2">
                        <b-button variant="primary" class="mt-2 mr-2">Update</b-button>
                    </b-col>
                </b-row>
            </b-tab> -->
        </b-tabs>
    </b-card>
</template>
  
<script>
import {
    BDropdown,
    BAvatar,
    BBadge, BSidebar, VBToggle,
    BPagination, BDropdownItem, BFormSelect, BCard, BTabs, BTab, BCardText, BRow, BCol, BButton, BForm, BFormGroup, BFormInput
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import SmsTemplateEdit from './SMSTemplateEdit.vue'
import Ripple from 'vue-ripple-directive'
import util from '@/store/utils'
import api from '@/store/api'
export default {
    components: {
        BAvatar,
        BBadge, BSidebar,
        BPagination, BCard, BTabs, BRow, BCol, BButton, BForm, BFormGroup, BFormInput, BDropdown,
        BDropdownItem,
        BCardText, BFormSelect,
        BTab, VueGoodTable, SmsTemplateEdit
    },
    directives: {
        'b-toggle': VBToggle,
        Ripple,
    },
    data() {
        return {
            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: 'Active',
                    field: 'active',
                },
                {
                    label: 'Screen Name',
                    field: 'screenName',
                },
                {
                    label: 'Template Name',
                    field: 'templateName',
                },
                {
                    label: 'SMS Template',
                    field: 'smsTemplate',
                },
                {
                    label: 'Action',
                    field: 'action',
                },
            ],
            rows: [],
            searchTerm: '',
            screenNameOptions: util.screenNameOptions,
            smsConfig: {},
        }
    },
    methods: {
        getSmsTemplate() {
            let self = this;
            self.rows = [];
            var axios = require('axios');
            var data = '';

            axios(api.getApi('get', '/masters/smsTemplates', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));

                    self.rows = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        deleteSmsTemplate(id) {
            let self = this;
            this.$swal({
                title: '',
                text: "Are You Sure You Want To Delete!",
                icon: 'error',
                customClass: {
                    confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    var axios = require('axios');
                    var data = '';
                    axios(api.getApi('delete', '/masters/smsTemplates/' + id, data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));
                            self.getSmsTemplate();
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            })
        },

        addEdit(isEdit, id, screenName, templateName, smsTemplate, isActive) {
            this.isEdit = isEdit;
            if (isEdit == false) {
                //Add 
                this.smsConfig = {
                    'id': 0,
                    'screenNameSelected': 0,
                    'templateName': '',
                    'smsTemplate': '',
                    'isActive': false

                }
            } else {
                // Update
                const object = this.screenNameOptions.find(obj => obj.id == screenName);
                this.smsConfig = {
                    'id': id,
                    'screenNameSelected': { 'id': object.id, 'screenName': object.screenName },
                    'templateName': templateName,
                    'smsTemplate': smsTemplate,
                    'isActive': isActive

                }
            }
        },

    },


    computed: {

    },
    created() {
        let self = this;
        self.getSmsTemplate();
    },
}
</script>
<style lang="css">
@import '~vue-good-table/dist/vue-good-table.css';
</style>